import './App.css';
import { Map, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import React, { useState, useEffect } from 'react';
import MapMarker from './planeIcon.svg'
import AirportIcon from './airport.svg'
import { Icon } from 'leaflet'
import { default as bezierSpline } from "@turf/bezier-spline";
import * as helpers from "@turf/helpers";

import axios from 'axios'

import RotatedMarker from "./RotatedMarker";
import PopUpFlightInfo from './PopUpFlightInfo';

const MapMarkerIcon = new Icon({
  iconUrl: MapMarker,
  iconSize: [40, 40]
});

const MapMarkerAirportIcon = new Icon({
  iconUrl: AirportIcon,
  iconSize: [40, 40]
});

function App() {

  const [ location, setLocation ] = useState([])

  const [ loading, setLoading ] = useState(true)

  let params = (new URL(document.URL)).searchParams;
  const airport = params.get('airport')
  
  const getInfo = () => {

    axios.get(
      `https://virtuallh.com/vam/get_current_location.php?ident=${airport}`
    ).then(function (response) {
      const location = [response.data[0].latitude_deg, response.data[0].longitude_deg]
      setLocation(location)
      setLoading(false)
    });

  }

  useEffect(() => {
    
    getInfo()

  }, [])

  return (

    loading ?

      'Loading...'

    :

    <Map center={location} zoom={13} scrollWheelZoom={false} style={{width: '100%', height: '100%', position: "absolute"}}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions"target="_blank">CARTO</a>'
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      />
    </Map>

  );
}

export default App;

/**
  {
    "dep_name": "London Heathrow Airport",
    "arr_name": "Geneva Cointrin International Airport",
    "dep_country": "GB",
    "arr_country": "CH",
    "callsign": "DLH101",
    "arrival": "LSGG",
    "departure": "EGLL",
    "flight_status": "BOARDING",
    "name_pilot": "Johnny",
    "surname": "",
    "plane_type": "A21N",
    "pending_nm": "406",
    "latitude": "51.4742601123111",
    "longitude": "-0.447187932927647",
    "heading": "269",
    "perc_completed": "0",
    "network": ""
  } 
  
  
  
  {
    "dep_name": "Zürich Airport",
    "arr_name": "Hannover Airport",
    "dep_country": "CH",
    "dep_lat": "47.4646987915039",
    "dep_lon": "8.54916954040527",
    "arr_country": "DE",
    "arr_lat": "52.461101532",
    "arr_lon": "9.68507957458",
    "callsign": "SWR105",
    "arrival": "EDDV",
    "departure": "LSZH",
    "flight_id": "20211130202819197LX818 ",
    "flight_status": "DESCEND",
    "name_pilot": "Christian",
    "surname": "Hardegen",
    "plane_type": "A320",
    "pending_nm": "13",
    "latitude": "52.2828137759608",
    "longitude": "9.90110635540276",
    "heading": "4",
    "perc_completed": "96",
    "network": ""
  },
  {
    "dep_name": "Sydney Kingsford Smith International Airport",
    "arr_name": "George Bush Intercontinental Houston Airport",
    "dep_country": "AU",
    "dep_lat": "-33.9460983276367",
    "dep_lon": "151.177001953125",
    "arr_country": "US",
    "arr_lat": "29.9843997955322",
    "arr_lon": "-95.3414001464844",
    "callsign": "ACA102",
    "arrival": "KIAH",
    "departure": "YSSY",
    "flight_id": "2021113035117300UA100 ",
    "flight_status": "CRUISE",
    "name_pilot": "Daniel",
    "surname": "Harlond",
    "plane_type": "B789",
    "pending_nm": "1267",
    "latitude": "20.4136390766402",
    "longitude": "-116.1868592439",
    "heading": "54",
    "perc_completed": "90",
    "network": ""
  },
  {
    "dep_name": "Copenhagen Kastrup Airport",
    "arr_name": "Amsterdam Airport Schiphol",
    "dep_country": "DK",
    "dep_lat": "55.6179008483887",
    "dep_lon": "12.6560001373291",
    "arr_country": "NL",
    "arr_lat": "52.3086013794",
    "arr_lon": "4.76388978958",
    "callsign": "DLH713",
    "arrival": "EHAM",
    "departure": "EKCH",
    "flight_id": "20211130214432890ZEU002 ",
    "flight_status": "BOARDING",
    "name_pilot": "Benoit",
    "surname": "Deluc",
    "plane_type": "TOUR",
    "pending_nm": "342",
    "latitude": "55.6249329887277",
    "longitude": "12.6490714705101",
    "heading": "88",
    "perc_completed": "0",
    "network": ""
  },
  {
    "dep_name": "Newark Liberty International Airport",
    "arr_name": "Singapore Changi International Airport",
    "dep_country": "US",
    "dep_lat": "40.6925010681152",
    "dep_lon": "-74.168701171875",
    "arr_country": "SG",
    "arr_lat": "1.3501900434494",
    "arr_lon": "103.994003295898",
    "callsign": "GEC146",
    "arrival": "WSSS",
    "departure": "KEWR",
    "flight_id": "20211129224737821SQ21 ",
    "flight_status": "CRUISE",
    "name_pilot": "Greg ",
    "surname": "Vincent",
    "plane_type": "A359",
    "pending_nm": "2114",
    "latitude": "36.5512426869109",
    "longitude": "102.491461597822",
    "heading": "177",
    "perc_completed": "78",
    "network": ""
  },
  {
    "dep_name": "Osaka International Airport",
    "arr_name": "Nagasaki Airport",
    "dep_country": "JP",
    "dep_lat": "34.7854995727539",
    "dep_lon": "135.438003540039",
    "arr_country": "JP",
    "arr_lat": "32.9169006348",
    "arr_lon": "129.914001465",
    "callsign": "ANA100",
    "arrival": "RJFU",
    "departure": "RJOO",
    "flight_id": "202112142845109NH781 ",
    "flight_status": "DESCEND",
    "name_pilot": "Koji",
    "surname": "Okada",
    "plane_type": "B738",
    "pending_nm": "12",
    "latitude": "32.9493448386905",
    "longitude": "130.153976022709",
    "heading": "193",
    "perc_completed": "96",
    "network": ""
  }*/